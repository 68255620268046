import React, { useEffect } from "react"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import getFirebase from "../../../utils/firebase"
import LessonCardByCategory from "./LessonCardByCategory"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f5f5f599",
  },
}))
const firebase = getFirebase()
const LessonCardList = props => {
  const classes = useStyles()
  const vocabulary = props.data.filter(i => i.categoryId === "vocabulary",i => i.categoryId === "nurse", i => i.categoryId === "vocabulary_machine")
  const conversation = props.data.filter(i => i.categoryId === "conversation")
  const sliderConversation = props.data.filter(
    i => i.categoryId === "slider-conversation"
  )
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase
          .database()
          .ref("User")
          .child(user.uid)
          .child("myTopics")
          .child(props.id)
          .update({
            total: props.data.length,
            topicId: props.id,
          })
      }
    })
  }, [])

  return (
    <div className={classes.root}>
      <Container>
        {vocabulary.length === 0 ? null : (
          <LessonCardByCategory data={vocabulary} name="Từ vựng" />
        )}
        {conversation.length === 0 ? null : (
          <LessonCardByCategory data={conversation} name="Hội thoại" />
        )}
        {sliderConversation.length === 0 ? null : (
          <LessonCardByCategory
            data={sliderConversation}
            name="Slider Conversation"
          />
        )}
      </Container>
    </div>
  )
}
export default LessonCardList
