import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import LessonCard from "./LessonCard"
import ALink from "../../common/ALink"

const useStyles = makeStyles(theme => ({
  title: {
    '&:first-of-type': {
      paddingTop: "30px"
    },
    width: "80vw",
    margin: "0px auto",
  },
  lessons: {
    width: "80vw",
    padding: "30px 0",
    margin: "0px auto",
    [theme.breakpoints.up("sm")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80vw",
    },
  },
  link: {
    textDecoration: "none",
  },
}))

function LessonCardByCategory(props) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.title}>
        <Typography variant="h5" color="primary">{props.name}</Typography>
      </div>
      <Grid container spacing={5} justify="space-between" className={classes.lessons}>
        {props.data ? props.data.map((item, index) => {
          const path = `/topics/${item.topicId}/${item.categoryId}/${item.id}`
          return (
            <Grid item xs={12} lg={6} key={index}>
              <ALink to={path} className={classes.link}>
                <LessonCard data={item} />
              </ALink>
            </Grid>
          )
        })
          : null}
      </Grid>
    </>
  )
}

export default LessonCardByCategory
