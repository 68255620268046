import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import Image from "../../common/Image"

const useStyles = makeStyles({
  image: {
    width: "85px",
    height: "64px",
  },
  card: {
    boxShadow: "4px 4px 15px 3px #D0D2D4",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      transform: "translateY(-3px)",
    },
  },
})

const LessonCard = ({ data }) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item xs={8}>
          <CardContent>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              gutterBottom
            >
              {data.category.vn}
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
              {data.title.vn}
            </Typography>
          </CardContent>
        </Grid>
        <Grid container item xs={3} alignItems="center" justify="center">
          <Image className={classes.image} {...data.img} />
        </Grid>
      </Grid>
    </Card>
  )
}
export default LessonCard
