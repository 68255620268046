import React from "react"
import withRoot from "../../components/shell/withRoot"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Header from "../../components/shell/Header"
import Breadcrumb from "../../components/common/Breadcrumb"
import LessonCardList from "../../components/learning/common/LessonCardList"

function TopicsListPage({ params ,data}) {  
  const { topicId } = params
  let lessons = []
  const addLessonVocabulary = lessonArr => {
    for (let index = 0; index < lessonArr.length; index++) {
      lessons.push(lessonArr[index])
    }
  }
  // const addLessonConversation = lessonArr => {
  //   for (let index = 0; index < lessonArr.length; index++) {
  //     lessons.push(lessonArr[index])
  //   }
  // }
  addLessonVocabulary(data.allVocabularyJson.nodes)
  // addLessonConversation(data.allConversationJson.nodes)
  return (
    <React.StrictMode>
      <SEO title="Profile" />
      {topicId && (
        <React.StrictMode>
          <Header data={data.allTopicsJson.nodes.find(i => i.id === topicId)} />
          <Breadcrumb data={data.allTopicsJson.nodes} />
          <LessonCardList
            data={lessons.filter(i => i.topicId === topicId)}
            id={topicId}
          />
        </React.StrictMode>
      )}
    </React.StrictMode>
  )
}

export default withRoot(TopicsListPage)
export const query = graphql`
  {
    homeJson {
      descriptions
      title
    }
    allTopicsJson {
      nodes {
        id
        breadcrumb
        lessonDescription
        lessonTitle
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
    }
    allVocabularyJson(sort: {fields: order}) {
      nodes {
        order
        id
        topicId
        breadcrumb
        categoryId
        category {
          jp
          vn
        }
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        title {
          jp
          vn
        }
      }
    }

  }
  
`
