import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: "30px",
  },
  root: {
    paddingTop:"40px",
    width: "100%",
    margin: "0px auto",
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80vw",
    },
  },
}))

const Header = props => {
  const classes = useStyles()
  return props.data ? (
    <div className={classes.root}>
    <Container>
      
      <Typography align="justify" variant="h4" paragraph>
        {props.data.lessonTitle}
      </Typography>
      <div className={classes.description}>
        {props.data.lessonDescription.split(".").map((item, index) => {
          return (
            <Typography
              key={index}
              align="justify"
              variant="subtitle1"
              gutterBottom
            >
              {item}
            </Typography>
          )
        })}
      </div>
      
    </Container></div>
  ) : null
}

export default Header
